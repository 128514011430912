* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  color: var(--text-black) !important;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
}
.app {
  position: relative;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 999;
}

.btn-light {
  color: var(--text-color) !important;
}

.btn-primary {
  background-color: var(--text-color) !important;
  color: var(--color-light) !important;
  border-color: var(--text-color) !important;
}

.text-theme {
  color: var(--text-color) !important;
}

.pointer {
  cursor: pointer;
}

.form-group .form-control {
  background: rgba(176, 186, 195, 0.4);
  border-radius: 16px;
  height: 70px;
  padding: 16px 20px;
  color: #001c1f;
}

.form-control:focus {
  background: rgba(176, 186, 195, 0.4);
  border: 1px solid #001c1f75;
  box-shadow: none;
}

.form-group label {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 4px;
  text-transform: capitalize;
}

.register-modal,
.learn-more-modal,
.full-release-modal,
.terms-condition-modal {
  padding-top: 42px;
  padding-bottom: 42px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #001c1f; /* Set your desired background color */
  background-size: 40%; /* Adjust to cover the entire screen */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.close-modal {
  position: absolute;
  right: 12px;
  top: 12px;
}
.go-back {
  position: absolute;
  left: 12px;
  top: 12px;
}
.close-modal img,
.go-back img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.content .content-heading {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.content .content-text p,
.content .content-text li {
  font-size: 14px;
  text-align: justify;
}
.content .content-text li {
  padding-bottom: 8px;
}
.theme-btn {
  background-color: #ffffff;
  color: #25adb1;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.theme-btn:hover,
.highlighted-btn {
  background-color: #25adb1 !important;
  color: #fff !important;
}
.fz-20 {
  font-size: 20px !important;
}
@media (max-width: 992px) {
  .register-modal,
  .learn-more-modal,
  .full-release-modal,
  .terms-condition-modal {
    padding-top: 36px;
    padding-bottom: 36px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .close-modal {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .close-modal img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .content .content-heading {
    font-size: 16px;
  }
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #05aaaf !important;
}
.modal-body {
  border-radius: 7px;
}

.fw-600 {
  font-weight: 600 !important;
}

.valid-feedback {
  color: #002c31 !important;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer !important;
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 1024px;
  }
}
.info-window {
  text-align: center;
}
.info-window img {
  border-radius: 100px;
  width: 50px;
  height: 50px;
  background-color: #fefefe;
  border: 1px solid #001c1f;
  margin: auto;
  object-fit: cover;
}
.info-window .type {
  font-weight: 700;
  font-size: 13px;
}
.info-window .username,
.info-window .email {
  font-weight: 400;
  font-size: 11px;
}
