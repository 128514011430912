.terms-conditions h1 {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.8px;
}
@media (max-width: 992px) {
  .terms-conditions h1 {
    font-size: 16px;
  }
}
.terms-conditions .content {
  max-height: 56vh;
  overflow: auto;
}