.registration-page {
  padding-top: 90px;
  padding-bottom: 90px;
}
.registration-page .section-heading h1 {
  color: #002c31;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.8px;
  margin-bottom: 30px;
}
.registration-page .registration-form-wrapper {
  background-color: #fff;
  border-radius: 17px;
  color: #fff;
  box-shadow: 0px 2px 12px 0px #14142b1f;
  border: 1px solid #eff0f6;
  padding: 30px 20px;
}


@media (min-width: 768px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
@media (max-width: 768px) {
  .registration-page {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
