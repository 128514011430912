footer {
  background-color: #002c31;
}
a {
  text-decoration: none;
  cursor: pointer;
}
footer .footer-heading {
  color: #fff;
  position: relative;
  margin-bottom: 26px;
  font-size: 16px;
}
footer p.footer-link {
  font-size: 13px;
  margin-bottom: 4px;
}
footer .footer-heading:after {
  content: "";
  height: 2px;
  background-color: #00898d;
  position: absolute;
  width: 60px;
  bottom: -12px;
  left: 0;
}
footer .social-platforms {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
footer .social-platforms a {
  margin-right: 8px;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  border-radius: 100%;

}
footer .social-platforms a:hover{
  border: 1px solid white;
  border-radius: 100%;
}

footer .social-platforms a img {
  width: 30px;
  cursor: pointer;
}
.text-reset {
  color: #bbbbbb !important;
}
footer .rights-reserved {
  color: #bbbbbb;
  font-weight: 400;
}
.footer-link a {
  transition: all 0.3s ease-in-out;
}
.footer-link a:hover {
  color: white !important;
  text-decoration: underline;
}


@media (max-width: 768px) {
  .footer-heading:after {
    left: 50% !important;
    transform: translate(-50%, 6px);
  }
  footer .social-platforms {
    justify-content: center;
  }
}





/* 

footer.black {
  background-color: #000;
  padding-top: 30px;
  padding-bottom: 30px;
}
footer.black .follow-us {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
}
footer.black .social-links {
  padding-top: 10px;
  padding-bottom: 10px;
}
footer.black .social-links ul {
  padding: 0px;
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: center;
}
@media (max-width: 992px) {
  footer.black .social-links ul {
    justify-content: center !important;
  }
}
footer.black .social-links ul li {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
footer.black .social-links ul li:first-child {
  padding-left: 0px;
}
footer.black .all-rights {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
footer .app-images img {
  max-width: 190px;
}
@media (max-width: 768px) {
  footer.black .all-rights {
    font-size: 10px !important;
    padding-bottom: 10px;
  }
  footer .app-images img {
    max-width: 120px;
  }
}
footer .footer-links ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .footer-links ul li {
  padding: 0px 12px;
}

footer .footer-links ul li a {
  color: #fff !important;
  text-decoration: none !important;
}
footer .footer-links ul li a:hover {
  cursor: pointer;
  text-decoration: underline !important;
  color: #05aaaf !important;
}

@media (max-width: 992px) {
  footer .footer-links ul {
    justify-content: center;
    padding: 0px !important;
    flex-wrap: wrap;
  }
  footer .footer-links ul li {
    padding: 0px 6px;
  }
  footer .footer-links ul li a {
    font-size: 12px;
  }
  footer.black {
    padding-top: 20px;
    padding-bottom: 20px;
  }
} */
