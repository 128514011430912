.register-form h1 {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.8px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.register-form .form-group .form-control {
  padding: 8px 14px;
  color: #fff;
  height: 50px;
  border-radius: 12px;
  border: 1px solid #fff;
  background: #3030306e;
}

.register-form .form-group label {
  color: #fff;
  text-transform: none;
}

.register-form .form-group {
  margin-bottom: 20px;
}

.register-form .form-group .form-control.invalid-input {
  border-color: var(--bs-form-invalid-color) !important;
  color: var(--bs-form-invalid-color) !important;
}

.invalid-feedback {
  display: block;
}

.register-form .success-message {
  color: #fff;
  padding-top: 40px;
  text-align: center;
  padding-bottom: 40px;
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 992px) {
  .register-form h1 {
    font-size: 20px !important;
  }
  .register-form .success-message {
    font-size: 16px;
  }
}

.white .register-form h1 {
  color: #05aaaf !important;
}
.white .register-form .form-group .form-control {
  color: #002c31 !important;
  border: 1px solid #002c31 !important;
  background: #fff;
}

.white .register-form .form-group label {
  color: #002c31 !important;
}

.white .register-form .form-group .form-control.invalid-input {
  border-color: var(--bs-form-invalid-color) !important;
  color: var(--bs-form-invalid-color) !important;
}

.white .register-form .success-message {
  color: #002c31 !important;
}
