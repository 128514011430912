.terms-conditions-page h1 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.8px;
}

@media (max-width: 992px) {
  .terms-conditions-page h1 {
    font-size: 16px;
  }
}
.terms-conditions-page .hero-section {
  background-color: #000;
  padding-top: 40px;
  padding-bottom: 30px;
}
