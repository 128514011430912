.project-title button {
    color: #25adb1 !important;
    font-size: 20px;
    font-weight: 600;
}

.plant-data {
    font-size: 24px;
    font-weight: 600;
    color: white;
    z-index: 99;
}

.project-date {
    font-size: 18px;
    font-weight: 500;
    color: white;
    z-index: 99;
}

.projects-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.projects-cards .porject-card {
    height: 200px;
    border: 1px solid #eff0f6;
    box-shadow: 0px 2px 12px 0px #14142b70;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-image: url('../../assets/images/features/feature-1.png');
}


.daiy-report-model .modal-content {
    background-image: url('../../assets/images/features/feature-1.png');
    background-color: transparent !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.porject-card:hover .hover-card-box {
    opacity: 1;
}

.porject-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    bottom: 0;
    background-color: rgba(0, 44, 49, 0.38);
    z-index: 0;
}

.hover-card-box {
    background: #25adb1f5;
    right: 0;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    z-index: 99;
}

.hover-card-box button {
    transition: all 0.3s ease-in-out;

}

.greenish-text {
    color: #25adb1 !important;
}

.show-details-btn {
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #25adb1;
    border: 1px solid transparent;
    border-radius: 12px;
    padding: 4px 18px;
    border: 1px solid white;
    border-radius: 4px;
}

.plant-image-data {
    height: 200px;
    object-fit: contain;
}

.accordian-table-data tbody tr p {
    width: 100px;
}

.project-heading button {
    color: #25adb1 !important;
    font-weight: 500;
}

.accordian-table-data thead .environ {
    font-size: 20px;
}

.accordian-table-data tbody tr {
    font-size: 16px;
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    border-bottom-color: #25adb1 !important;
}

.export-btn {
    border: transparent;
    background-color: #25adb1;
    color: white;
}

@media (min-width: 220px) and (max-width: 767px) {
    .projects-cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .projects-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}