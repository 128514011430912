.radio {
    margin: 0.5rem;
  }
  
  .radio input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  
  .radio input[type="radio"] + .radio-label::before {
    content: '';
    background: #f4f4f4; /* Replace with your color */
    border-radius: 100%;
    border: 1px solid darken(#f4f4f4, 25%); /* Replace with a darker version of your color */
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    position: relative;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
  
  .radio input[type="radio"]:checked + .radio-label::before {
    background-color: #00898D; /* Replace with your color */
    box-shadow: inset 0 0 0 4px #f4f4f4; /* Replace with your color */
  }
  
  .radio input[type="radio"]:focus + .radio-label::before {
    outline: none;
    border-color: #00898D; /* Replace with your color */
  }
  
  .radio input[type="radio"]:disabled + .radio-label::before {
    box-shadow: inset 0 0 0 4px #f4f4f4; /* Replace with your color */
    border-color: darken(#f4f4f4, 25%); /* Replace with a darker version of your color */
    background: darken(#f4f4f4, 25%); /* Replace with a darker version of your color */
  }
  
  .radio input[type="radio"] + .radio-label:empty::before {
    margin-right: 0;
  }
  