.tutorial-page {
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
}
.tutorial-page .side-nav {
  width: 326px;
  min-width: 326px;
  background-repeat: no-repeat;
  background-size: contain;
}
.tutorial-page .tutorials-list-wrapper {
  width: 100%;
  flex: 1;
  padding: 0px 30px;
  color: #090909;
}
.tutorial-page .tutorials-list-wrapper .heading-01 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 3px;
}

.tutorial-page .tutorials-list-wrapper .desc {
  font-size: 16px;
  font-weight: 300;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video {
  margin-bottom: 40px;
  cursor: pointer;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video .thumbnail img {
  width: 100%;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video .thumbnail {
  position: relative;
  margin-bottom: 20px;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video .thumbnail .duration {
  background-color: #fff;
  width: 42.45px;
  height: 21px;
  top: 9px;
  border-radius: 50px 0px 0px 50px;
  position: absolute;
  right: 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  color: #9500c0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video .tutorial-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video .tutorial-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video .tutorial-desc a {
  color: #00898d;
}
.tutorial-page .tutorials-list-wrapper .filters .btn-group button {
  border: 1px solid #e1e1e1;
  color: #575757 !important;
  background-color: transparent;
  font-size: 13px;
}
.tutorial-page .tutorials-list-wrapper .filters .btn-group button:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.tutorial-page .tutorials-list-wrapper .filters .btn-group button:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.tutorial-page .tutorials-list-wrapper .tutorial-video .thumbnail .edit-button {
  position: absolute;
  top: 9px;
  cursor: pointer;
  left: 10px;
}



@media (max-width: 992px) {
  .tutorial-page .side-nav {
    display: none;
  }
}
