.auth-wrapper {
  min-height: 80vh;
  background-color: #001c1f;
}
.login-page {
  padding-bottom: 50px;
  padding-top: 50px;
}
.login-page .login-form {
  background-color: #ffffff38;
  border-radius: 17px;
  color: #fff;
}
.login-page .login-form .welcome-back h1 {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #fff;
  text-align: center;
}
.login-page .login-form .get-started h3 {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff;
  text-align: center;
}
.login-page .login-form .promo-img img {
  border-radius: 15px;
}
.login-page .login-form .login-form-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 80%;
  margin: auto;
  color: #000;
}
.login-page button.submit {
  padding-left: 45px;
  padding-right: 45px;
  font-weight: 600;
}

.login-page .dont-have-account {
  color: rgb(255, 255, 255);
}
.login-page .dont-have-account a {
  color: #fff;
  font-weight: 600;
}

.login-page .login-form .sign-with-google button {
  color: rgba(19, 24, 30, 1) !important;
  border: 1px solid rgba(19, 24, 30, 1);
  border-radius: 10px;
}
.login-page .login-form .sign-with-google button img {
  width: 40px;
  margin-right: 16px;
}
.login-form .form-group .form-control.invalid-input {
  border-color: var(--bs-form-invalid-color) !important;
  color: var(--bs-form-invalid-color) !important;
}

.invalid-feedback {
  display: block;
}
.login-form .form-group .form-control {
  height: 46px;
  border-radius: 8px;
  background-color: #fff;
}
.login-form .form-group label {
  font-size: 12px;
  color: #fff;
}
