.grow-log-iocn {
    background: #00898D;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    padding: 8px;
}

.grow-log-card {
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid #3c4d4f;
}

.grow-log-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 44, 49, 0.38);
    z-index: 0;
  }

.grow-log-card h3 {
    font-weight: 700;
    font-size: 35px;
    z-index: 10;
}
.grow-log-card h3 span {
    color: #00898d !important;
}

.grow-log-options-sec h3 {
    color: #00898D;
    font-weight: 500;
}

.option-box {
    background-color: #001C1F;
    border-radius: 20px;
    height: 185px;
}
.grow-log-options-sec .swiper-slide {
    height: 185px;
}
.grow-log-options-sec .css-13cymwt-control {
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 0px !important;
    color: white !important;
}

.grow-log-options-sec .css-1wy0on6 { 
    display: none !important;
}
.grow-log-options-sec .css-1dimb5e-singleValue {
    color: white !important;
    text-align: center !important;
    font-size: 32px !important;
}
.grow-log-options-sec .css-t3ipsp-control {
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: transparent !important;
    border-color: white !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px white !important;
    box-sizing: border-box;
}
.grow-log-options-sec .css-10wo9uf-option {
    text-align: center !important;
    font-size: 22px !important;
}
.grow-log-options-sec .css-tr4s17-option {
    background-color: #001C1F !important;
    color: hsl(0, 0%, 100%);
    padding: 8px 12px;
    text-align: center !important;
    font-size: 22px !important;
}
.css-d7l1ni-option {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #DEEBFF;
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;
    text-align: center !important;
    font-size: 22px !important;
}
.grow-log-options-sec .mySwiper {
    width: 90%;
}
.grow-log-options-sec .swiper-slide {
    padding-left: 60px;
    padding-right: 60px;
}


.grow-log-options-sec .swiper-button-prev, .swiper-button-next {
    color: #d0d1d1 !important
}

.proceed-btn {
    backdrop-filter: blur(24px);
    background: #D9D9D91F;
    border: 0;
    color: white;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;

}
.plant-data-sec table input{
    background: transparent;
    border: 2px solid #FFFFFF9C;
    color: white;
    text-align: center;
    width: 120px;
    height: 50px;
    border-radius: 10px;
}
.doc-upload-btn {
    background-color: white;
    color: #00898D;
    border-radius: 10px;
    border: 0;
    font-size: 18px;
    font-weight: 500;
}

.save-btn {
    background-color: #00898d;
    color: white;
    backdrop-filter: blur(24px);
    border-radius: 10px;
    border: 0;
    font-size: 18px;
    font-weight: 500;
}

.feature {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.feature:hover {
    transform: scale(1.04);
}
.singleSubject {
    background-color: #FAF8F8;
}
.subject-icon {
    width: 60px;
    height: auto;
}
.cursor-pointer {
    cursor: pointer;
}


/* radio style  */

.list {
	flex: 0 0 20rem;
}

.list__item {
	position: relative;
}

.radio-btn {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	&:checked + .label {
		&::before {
			border: 0.4rem solid #00898D;
			background: #fff;
		}
	}
}

.label {
	display: flex;
	align-items: center;
	padding: 0.75rem 0;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.25s linear;
}

.label::before {
	display: inline-block;
	content: "";
	height: 1.525rem;
	width: 1.525rem;
	margin-right: 0.625rem;
	border: 0.6rem solid #fff;
    background-color: #fff;
	border-radius: 50%;
	transition: all 0.25s linear;
    box-shadow: 0px 4px 18px 0px #B6B2B2;

}

.singleDate {
    background-color: white;
    height: 160px;
}

.selected-date {
    background-color: #00898D;
    color: white;
}
.selected-date-sec {
    margin-top: 100px;
}
.selected-year-dates .swiper-button-prev, .swiper-button-next {
    color: #00898D !important;
}


.chat-main {
    background: #00000040;

}

.chat-msg {
    background-color: #FFFFFF;
    font-size: 18px;
    width: 80%;
}

.chat-profile {
    width: 50px;
    height: 50px;
    margin-top: -20px;
}
.message-send-sec {
    border-radius: 10px;
    border: 2px solid #FFFFFF;
}
.outline-none {
    outline: none !important;
}

.border-buttom {
    border-bottom: 1px solid #333333 !important;
}

.send-btns {
    background-color: #00898D;
    color: white;
    border-radius: 12px;
    font-size: 18px;
}
.send-border {
    border-left: 2px solid white;
}

.singleStep {
    color: white;
    background-color: #00898D;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.active-step {
    background-color: white !important;
    color: #00898D !important;
}
.singleStepspacer {
    border-top: 2px dotted #00898D;
    width: 98%;
    height: 2px;
    position: absolute;
    z-index: 1;
}
.btn-data {
    width: 120px;
    text-align: center;
}

.growth-select {
    width: 300px;
}
#projectName::placeholder {
    color: white !important;
}

.validate-error {
    bottom: -36px;
    font-size: 14px;
}
.email-status {
    font-size: 12px;
    background-color: #00898d;
    color: white;
    border-radius: 4px;
    padding: 2px 10px;
}
.email-date {
    font-size: 12px;
}

.compose-btn {
    background-color: #00898d;
    color: white;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}
.compose-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    transform: scale(1.04);
}
.email-lists {
    height: 500px;
    overflow-y: scroll;
    padding-right: 30px;
}

.mailList-main .email-lists::-webkit-scrollbar {
    width: 10px;
  }
  
  .mailList-main .email-lists::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  .mailList-main .email-lists::-webkit-scrollbar-thumb {
    background: #00898d;
    border-radius: 6px; 
  }
  
  .mailList-main .email-lists::-webkit-scrollbar-thumb:hover {
    background: #00898d; 
  }
.singleEmail .n-del-btn {
    top: 46px; 
    width: 24px;
    height: 24px;

}
.create-project-btn {
    background-color: white;
    border: 1px solid white;
    color: #00898d;
    padding: 12px 80px;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.create-project-btn:hover {
    transform: scale(1.05);
}


.tech-select-btn button {
    max-width: 220px;
    min-width: 220px;
}

.notification-main-header {
    height: 400px !important;
    overflow-y: scroll !important;
}

/* width */
.notification-main-header::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  .notification-main-header::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(183, 182, 182); 
    border-radius: 4px;
  }
   
  /* Handle */
  .notification-main-header::-webkit-scrollbar-thumb {
    background: #00898d; 
    border-radius: 4px;
  }
  
  /* Handle on hover */
  .notification-main-header::-webkit-scrollbar-thumb:hover {
    background: #00898d; 
  }
.dark-spacer {
    background-color: #00898d;
    height: 1px;
    width: 100%;
}
.text-transform-lowercase {
    text-transform: lowercase !important;
}


.growDoc-main .doc-upload-btn {
     border: 1px solid;
}

.growDoc-main .uploadImage {
    height: 300px;
    border: 1px solid #00898d;
}

.growDoc-main .uploadImage img { 
    object-fit: contain;
}

.start-growing .css-t3ipsp-control:hover {
    border-color: #00898d !important;
}
.start-growing .css-t3ipsp-control {
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    background-color: hsl(0, 0%, 100%);
    border-color: #00898d !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px #00898d !important;
}

.start-growing .css-d7l1ni-option {
    background-color: #DEEBFF;
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;
    text-align: start !important;
    font-size: 14px !important;
}
.start-growing .css-d7l1ni-option:active {
    background-color: #B2D4FF;
}
.start-growing .css-tr4s17-option {
    background-color: #00898d !important;
    color: hsl(0, 0%, 100%);
}

.proceed-btn {
    background-color: #00898D;
    color: white;
    border-radius: 12px;
}

.react-calendar {
    width: 100% !important;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    border-radius: 10px;
    font-family: 'Arial', 'Helvetica', sans-serif;
    line-height: 1.125em;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #00898D !important;
    border-radius: 4px;
}
.react-calendar__tile--active {
    background: #00898D !important;
    border-radius: 4px;
    color: white;
}



@media (min-width: 220px) and (max-width: 767px) { 
    .notification-badge {
        font-size: 9px;
        background-color: #bb2124;
        position: absolute;
        top: -6px;
        left: 17px;
        color: white;
        border-radius: 20px;
        padding: 2px;
        min-width: 20px;
        min-height: 20px;
        display: flex
    ;
        justify-content: center;
        align-items: center;
    }
    .user-dropdown .dropdown-menu[data-bs-popper] {
        top: 100%;
        left: 0 !important;
        margin-top: var(--bs-dropdown-spacer);
        right: 0 !important;
    }
    .chat-msg {
        background-color: #FFFFFF;
        font-size: 14px;
        width: 100%;
    }
    .chat-profile {
        width: 30px;
        height: 30px;
        margin-top: -10px;
    }
    .subject-icon {
        width: 30px;
        height: auto;
    }
    .list__item span {
        font-size: 14px !important;
    }

    .grow-log-card h3 {
        font-size: 26px;
    }
    .grow-log-iocn {
        width: 55px;
        height: 55px;
    }
    .grow-log-options-sec .swiper-slide {
        padding-left: 38px;
        padding-right: 38px;
    }
    .grow-log-options-sec .mySwiper {
        width: 100%;
        margin-bottom: 45px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {  
    .list__item span {
        font-size: 18px !important;
    }
    .subject-icon {
        width: 38px;
        height: auto;
    }
}