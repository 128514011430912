.otp-inputs input {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
}
.resend-otp-btn {
    text-decoration: underline;
    color: #00898d;
}


@media (min-width: 200px) and (max-width: 766px) {
    .otp-inputs input {
        width: 35px;
        height: 35px;
        border-radius: 10px;
        border: 1px solid gray;
        flex-wrap: wrap;
    }
}