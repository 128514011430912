.learn-more h1 {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.8px;
}
.terms-conditions-link {
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
.learn-more .content .content-text p,
.learn-more .content .content-text li {
  text-align: center !important;
}
.learn-more .content {
  max-height: 50vh;
  overflow: auto;
}

.learn-more-footer-btn {
  max-width: 230px;
  width: 100%;
  height: 29px;
  border-radius: 4px;
}
@media (max-width: 992px) {
  .learn-more h1 {
    font-size: 16px;
  }
}
