.pricing-page {
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pricing-page h1.heading {
  color: #002c31;
  font-weight: 600;
}
.pricing-page p {
  color: #6f6c90;
}
.pricing-page p {
  margin-bottom: 0;
  padding-right: 10px;
}
.pricing-page .choose-currency {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-page .choose-currency select {
  border: 1px solid #eff0f6;
  border-radius: 6px;
  background-color: #fff;
  font-weight: 600;
  color: #514f6e;
  padding: 6px 4px;
}

.pricing-page .plans .plan {
  border: 1px solid #eff0f6;
  box-shadow: 0px 2px 12px 0px #14142b70;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 20px 18px;
  padding-top: 50px;
  padding-bottom: 40px;
  min-height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-page .plans .plan .plan-name {
  display: flex;
  align-items: center;
}
.pricing-page .plans .plan .plan-name .info {
  padding-left: 10px;
}
.pricing-page .plans .plan .plan-name .info .serve {
  font-size: 16px;
  color: #6f6c90;
  font-weight: 600;
}
.pricing-page .plans .plan .plan-name .info .name {
  font-size: 22px;
  color: #002c31;
  font-weight: 600;
}
.pricing-page .plans .plan .plan-desc {
  font-size: 16px;
  color: #6f6c90;
  font-weight: 400;
}
.pricing-page .plans .plan .price {
  display: flex;
  align-items: end;
}
.pricing-page .plans .plan .price .price-value {
  font-size: 40px;
  color: #002c31;
  font-weight: 600;
}
.pricing-page .plans .plan .price .period {
  color: #6f6c90;
  font-size: 16px;
  position: relative;
  bottom: 10px;
}
.pricing-page .plans .whats-included .head {
  color: #002c31;
  font-size: 14px;
  font-weight: 600;
}
.pricing-page .plans .whats-included .included {
  display: flex;
  align-items: center;
  margin-top: 14px;
}
.pricing-page .plans .whats-included .included img {
  width: 18px;
  margin-right: 10px;
}
.pricing-page .plans .whats-included .included p {
  font-size: 14px;
  color: #002c31;
  font-weight: 500;
}
.pricing-page .plans .plan .get-start-button {
  margin-top: 30px;
}
.pricing-page .plans .plan .get-start-button button {
  width: 100%;
  text-align: center;
  height: 62px;
  background-color: #25adb1;
  color: white;
  border-radius: 100px;
  font-weight: 600;
}
.pricing-page .plans .plan.active .get-start-button button {
  background-color: #fff;
  color: #25adb1;
}
.pricing-page .plans .plan.active {
  background-color: #25adb1;
  border: none;
}
.pricing-page .plans .plan.active .plan-name .info .serve {
  color: #eff0f6;
}
.pricing-page .plans .plan.active .plan-name .info .name {
  color: #fff;
}
.pricing-page .plans .plan.active .plan-desc {
  color: #d9dbe9;
}
.pricing-page .plans .plan.active .price .price-value {
  color: #fff;
}
.pricing-page .plans .plan.active .price .period {
  color: #d9dbe9;
}
.pricing-page .plans .plan.active .whats-included .head {
  color: #fff;
}
.pricing-page .plans .plan.active .whats-included .included p {
  color: #fff;
}

.active-tag {
  background-color: white;
  right: -56px;
  transform: rotate(40deg);
  top: 25px;
}
.plan {
  overflow: hidden;
}
.active-tag {
  display: none;
}

.currentActivePlan .active-tag{
  display: block !important;
}

.active-tag p {
  font-size: 15px;
  text-align: center;
  color: #25adb1;
  font-weight: 600;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8); 
}