.slider-container .slide-img {
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.slider-container .slide-img img {
  width: 45% !important;
  height: 300px;
}
.slider-container .slick-track .slick-current + .slick-slide .slide-img img {
  width: 100% !important;
}

.slider-container .slick-track {
  display: flex !important;
  align-items: center;
}
.slick-prev:before,
.slick-next:before {
  color: #000;
}
.slick-prev {
  left: -8px;
}
.slick-next {
  right: -8px;
}
@media (max-width: 992px) {
  .slider-container .slide-img img {
    width: 48% !important;
  }
  .slider-container .slick-track .slick-current + .slick-slide .slide-img img {
    width: 90% !important;
  }
}
