@font-face {
  font-family: 'Ameyallinda';
  src: url('../../assets/font/Ameyallinda\ Signature.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.AmeyallindaFont {
  font-family: 'Ameyallinda', sans-serif;
font-size: 130px;
font-weight: 400;
line-height: 158.5px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.start-growing {
  background-color: #001c1f;
}
.start-growing .hero {
  margin-top: -80px;
  padding-top: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-image: url('../../assets/images/start-growing-bg.png');
  position: relative;

}
.start-growing .hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 44, 49, 0.38);
  z-index: 0;
}
.start-growing .hero .container {
  height: 100%;
}
.start-growing .hero .hero-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  z-index: 10;
  position: relative;
}
.start-growing .hero .hero-content .from-seeds-to {
  font-weight: 900;
  display: flex;
  align-items: center;
  color: var(--color-light);
}

/* amazing-features-section */
.start-growing .amazing-features-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.start-growing .amazing-features-section .section-heading h1 span:first-child {
  color: var(--text-color);
  font-weight: 800;
}
.start-growing .amazing-features-section .feature {
  height: 280.54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  border-radius: 3px;
  margin-bottom: 50px;
}
.start-growing .amazing-features-section .feature::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 44, 49, 0.38);
  z-index: 0;
}


.start-growing .amazing-features-section .feature .feature-text {
  font-weight: 700;
  font-size: 35px;
  z-index: 10;
  position: relative;
}
.start-growing .amazing-features-section .feature .feature-text span {
  color: var(--text-color);
}

.start-growing .amazing-features-section .feature-footer-text p {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 0px 20px;
  text-align: center;
  font-weight: 400;
}

/* selected-feature-section */
.selected-feature-section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.selected-feature-section .section-heading h1 {
  font-weight: 700;
  font-size: 66px;
  text-align: center;
  margin-bottom: 40px;
}

.selected-feature-section .section-heading h2 {
  font-weight: 700;
  font-size: 66px;
  text-align: center;
  margin-bottom: 40px;
}

.selected-feature-section .growx-feature {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 316px;
  height: 220px;
  margin-bottom: 20px;
}
.selected-feature-section .growx-feature img {
  width: 200px;
}
.fw-500 {
  font-weight: 500;
}

/* plant-data */
.plant-data-section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.plant-data-section .section-heading h1 {
  font-weight: 700;
  font-size: 66px;
  text-align: center;
  margin-bottom: 40px;
}
.plant-data-section .environment {
  font-weight: 700;
  font-size: 46px;
}
.plant-data-section .low,
.plant-data-section .high {
  font-weight: 500;
  font-size: 28px;
}
.plant-data-section h2.env-type {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 10px;
}
.plant-data-section input.env-value {
  height: 100%;
  border-radius: 10px;
  border: 1px rgba(255, 255, 255, 0.61) solid;
  width: 103px;
  height: 52px;
  background-color: transparent;
  color: #fff !important;
  padding: 10px;
}
.plant-data-section button {
  font-weight: 600;
}
.plant-data-section .submit-btn {
  background-color: rgba(217, 217, 217, 0.12);
  border-radius: 10px;
  color: #fff;
  border: none;
}
/* growx-service-section */
.growx-service-section .growx-service {
  padding: 16px 24px;
  background-color: #faf8f8;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 30px;
}
.growx-service-section .growx-service .service-name {
  display: flex;
  align-items: center;
}
.growx-service-section .growx-service .service-name div.check-box {
  box-shadow: 0px 4px 18px #b6b2b2;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 100%;
}
.growx-service-section .growx-service .service-name div.name {
  color: #000;
  font-size: 20px;
  font-weight: 300;
  padding-left: 14px;
}
.growx-service-section .growx-service .service-icon img {
  width: 50px;
  height: 50px;
}
/* date-select-scroll */
.day-select-scroll {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: scroll;
}
.day-select-scroll .day-box {
  max-height: 170px;
  height: 170px;
  width: 110px;
  min-width: 110px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;
  padding: 20px 10px;
  font-size: 30px;
  flex-direction: column;
  font-weight: 600;
  line-height: 1.1;
  cursor: pointer;
}
.day-select-scroll .day-box:hover {
  background-color: rgba(0, 137, 141, 1);
  color: #fff;
}

/* grower-form-wrapper */
.grower-form-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
}

.grower-form-wrapper form {
  padding: 40px 40px;
  color: #fff;
}
.grower-form-wrapper form .form-group label {
  color: #fff;
  margin-bottom: 10px;
}
.grower-form-wrapper form .form-group input {
  color: #fff;
  border: none;
  background-color: rgba(255, 255, 255, 0.25);
}

@media (min-width: 220px) and (max-width: 767px) {
  .AmeyallindaFont {
    font-size: 50px;
    line-height: normal;
}
.start-growing .amazing-features-section .feature {
  height: 280.54px;
  margin-bottom: 15px;
}
.start-growing .amazing-features-section .feature-footer-text p {
  font-size: 19px;
  margin-bottom: 40px;
  padding: 0px 20px;
}
}

@media (min-width: 768px) and (max-width: 1024px) { 
  .start-growing .amazing-features-section .feature-footer-text p {
    font-size: 20px;
    margin-bottom: 40px;
    padding: 0px 20px;
  }
}


@media (min-width: 768px) {
  .start-growing .hero .hero-content .from-seeds-to {
    font-size: 60px;
  }
}
@media (min-width: 1200px) {
  .start-growing .hero .hero-content .from-seeds-to {
    font-size: 86px;
  }
}
@media (min-width: 1400px) {
  .start-growing .hero .hero-content .from-seeds-to {
    font-size: 88px;
  }
}
@media (max-width: 768px) {
  .start-growing .hero .hero-content img {
    width: 100px;
  }
  .start-growing .section-heading h1 {
    font-size: 32px !important;
  }
  .start-growing .section-heading h2 {
    font-size: 32px !important;
  }
  .plant-data-section .environment {
    font-weight: 700;
    font-size: 20px;
  }
  .plant-data-section .low,
  .plant-data-section .high {
    font-weight: 500;
    font-size: 20px;
  }
  .plant-data-section .env-type {
    font-weight: 500;
    font-size: 18px;
  }
  .plant-data-section input.env-value {
    width: 100%;
  }
}
