.contact-us-page.body-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}
.contact-us-page .contact-us-wrapper {
  border-radius: 8px;
  padding: 40px 80px;
  backdrop-filter: blur(20px);
  box-shadow: 0px 7px 8px 6px #0000000f;
}
.contact-us-page .contact-us-wrapper h1 {
  color: #05aaaf;
  font-weight: 800;
  text-align: center;
  font-size: 46px;
}

.contact-us-page .contact-us-wrapper p {
  color: #000;
  font-weight: 400;
  text-align: center;
}
.contact-us-page .contact-us-wrapper label {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0px;
}
.contact-us-page .contact-us-wrapper input.form-control {
  background-color: #f8f8f8;
  color: #000;
  border: 1px solid #707070;
  border-radius: 8px;
  height: 48px;
}
.contact-us-page .contact-us-wrapper textarea.form-control {
  background-color: #f8f8f8;
  color: #000;
  border-radius: 8px;
  border: 1px solid #707070;
  min-height: 128px;
}
.contact-us-page .form-control:focus {
  outline: none;
  box-shadow: none;
}
.contact-us-page .btn-submit {
  background-color: #05aaaf !important;
  border: 1px solid #05aaaf;
  color: #fff !important;
  font-weight: 800;
  width: 240px;
  max-width: 240px;
  margin: auto;
  text-align: center;
  font-size: 20px;
  letter-spacing: 1px;
}
@media (max-width: 992px) {
  .contact-us-page.body-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .contact-us-page .contact-us-wrapper {
    padding: 40px 20px;
  }
  .contact-us-page .contact-us-wrapper h1 {
    font-size: 26px;
  }
  .contact-us-page .contact-us-wrapper p {
    font-size: 10px;
  }
}
.invalid-feedback {
  color: red !important;
  font-size: 10px;
}
