@media (min-width: 992px) {
  .nav-link {
    margin-left: 18px;
  }
}
.navbar-toggler {
  background-color: #23aeb1;
}
.nav-link {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 13px;
  border-radius: 100px;
}
.navbar-dark {
  background-color: #fff;
  box-shadow: 0px 4px 20.299999237060547px 0px #0000001f;
}
.nav-link.active-nav {
  background-color: var(--text-color) !important;
  color: #fff !important;
  border-radius: 100px;
  padding: 8px 20px !important;
}
.header-btn:hover {
  color: #000000 !important;
}
.navbar-dark .toggle {
  background-color: transparent !important;
  border: none !important;
  padding: 5px 8px;
}
.navbar-dark .toggle:after {
  content: "";
  display: none;
}
.dropdown-menu.show {
  padding: 0px;
  border-radius: 4px;
  border: 1px solid #eeeded;
  min-width: 80px
}
.dropdown-item {
  font-size: 13px;
}

.header-notification-link {
  cursor: pointer;
}

.header-notification-link .noti-bill {
  color: #00898D;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: auto !important;
  margin-top: var(--bs-dropdown-spacer);
  right: 0 !important;
}

.notification-count {
  font-size: 16px;
  color: #00898D;
  font-weight: 600;
}

.view-all-notifications {
color:#636569;
font-size: 13px;
font-weight: 500;
}
.notification-badge {
  font-size: 12px;
  background-color: #bb2124;
  position: absolute;
  top: -6px;
  left:25px;
  color: white;
  border-radius: 20px;
  padding: 2px;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.view-all-notifications:hover{
  text-decoration: underline;
  color: #00898D !important;
}
.header-notification-content {
  font-size: 12px;
}

.notification-model-main {
  width: 350px;
}



@media (min-width: 220px) and (max-width: 767px) {
  .n-descr {
      font-size: 14px;
  }
  .n-title {
      font-size: 16px;
  }
  .n-type, .n-date {
      font-size: 12px;
  }
  .notification-model-main {
      width: 280px;
    }
}

@media (max-width: 992px) {
  .nav-link {
    border-radius: 6px !important;
  }
  .nav-link.active-nav {
    border-radius: 6px !important;
    padding: 0px 0px !important;
    background-color: transparent !important;
    color: var(--text-color) !important;
  }
}
