.notification-main .hero {
    margin-top: -80px;
    padding-top: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-image: url('../../assets//images/start-growing-bg.png');
    position: relative;
}


.notification-main .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 44, 49, 0.38);
    z-index: 0;
}
.notification-main .hero .hero-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    z-index: 10;
    position: relative;
}
.notification-main {
    background-color: #001c1f;
}
.notification-main .hero .hero-content .from-seeds-to {
    font-weight: 900;
    display: flex
;
    align-items: center;
    color: var(--color-light);
}

.notification-sec .single-notification{
    border-bottom: 1px solid #f0f0f0;
}
.n-del-btn {
    top: 28px;
    background-color: #dee3e8;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: 	#bb2124;
    transition: all 0.3s ease-in-out;
}
.n-del-btn:hover svg{
    transform: scale(1.08);
}
.n-title {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.n-descr {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}

.n-type , .n-date {
    font-size: 18px;
    color: #a8a8a8;
    padding-bottom: 10px;
}

.single-notification.read {
    background-color: white;
}
.single-notification.unread {
    background-color: #f6fafd;
}





@media (min-width: 220px) and (max-width: 767px) {
    .n-descr {
        font-size: 14px;
    }
    .n-title {
        font-size: 16px;
    }
    .n-type, .n-date {
        font-size: 12px;
    }
    .notification-model-main {
        width: 280px;
      }
}


@media (min-width: 768px) and (max-width: 1024px)  {
    .n-descr {
        font-size: 16px;
    }
    .n-title {
        font-size: 18px;
    }
    .n-type, .n-date {
        font-size: 14px;
    }
}



@media (min-width: 1400px) {
    .notification-main .hero .hero-content .from-seeds-to {
        font-size: 88px;
    }
}