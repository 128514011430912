.profile-page {
  background-color: #fff;
  padding-bottom: 120px;
}

.profile-page .section-heading h1 {
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  color: #002c31;
}

.profile-page .profile-form-wrapper {
  box-shadow: 0px 2px 12px 0px #14142b1f;
  border: 1px solid #eff0f6;
  padding: 30px 38px;
  border-radius: 20px;
  padding: 30px 38px;
}

.profile-page .divider {
  height: 2px;
  width: 100%;
  background-color: #0000006b;
  margin-top: 24px;
  margin-bottom: 20px;
  opacity: 0.2;
}

.profile-page .linked-accounts h3 {
  color: #000;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
}

.profile-page .linked-accounts p {
  font-size: 14px;
  color: #000;
}

.profile-page .accounts {
  margin-top: 30px;
}

.profile-page .accounts .google {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-page .accounts .google .account-info {
  display: flex;
  align-items: center;
}

.profile-page .accounts .google .account-info img {
  width: 52px;
  height: 52px;
}

.profile-page .accounts .google .account-info .account-name {
  padding-left: 20px;
}

.profile-page .accounts .google .btn-default.connect {
  background: #ff3333;
  border-radius: 7px;
  box-shadow: 2px 3px 2px rgb(0 44 49 / 17%);
  color: #fff !important;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
}

.profile-page button.submit {
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 600;
}

.profile-page .image-wrapper {
  text-align: center;
  position: relative;
  bottom: 110px;
}

.profile-page .image-wrapper button {
  padding-left: 30px;
  padding-right: 30px;
}

.profile-page .image-wrapper .profile-picture {
  width: 158px;
  height: 158px;
  margin-bottom: 30px;
}

.profile-page .profile-tabs {
  display: flex;
  align-items: center;
}

.profile-page .profile-tabs .profile-tab {
  border: 1px solid #002c31;
  border-radius: 30px;
  padding: 3px 14px;
  color: #002c31;
  margin-right: 11px;
  cursor: pointer;
}

.profile-page .profile-tabs .profile-tab.disabled {
  cursor: none;
  opacity: 0.4;
}

.profile-page .profile-tabs .profile-tab.active-tab {
  border: 2px solid #00898d;
  background-color: #00898d;
  color: #fff;
}


@media (max-width: 992px) {
  .profile-page .profile-form-wrapper {
    padding: 16px;
  }

  .form-group {
    margin-bottom: 14px;
  }

  .profile-page .profile-tabs {
    flex-wrap: wrap;
  }

  .profile-page .profile-tabs .profile-tab {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}

@media (max-width: 768px) {}

.profile-page .form-group .form-control {
  height: 58px;
  border-radius: 14px !important;
}

.profile-page .success-message {
  color: #00898d;
  text-align: center;
  padding-top: 20px;
  font-weight: 600;
  font-size: 20px;
}

.profile-img {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #00898D;
  background-color: #00898d;
}

.profile-upadate-btns button {
  border-radius: 7px;
  border: 0;
  width: 160px;
  padding: 6px 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;

}

.profile-upadate-btns .upload {
  background: #00898D;
  color: white;
}
.profile-upadate-btns .upload:hover {
  background: white;
  color: #00898D;
}
.profile-upadate-btns .remove {
  background-color: #FFFFFF;
  color: #00898D;
}
.profile-upadate-btns .remove:hover {
  background-color: #00898D;
  color: white;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 14px 14px 14px 14px !important;
}
.react-tel-input .flag-dropdown {
border-top-left-radius: 14px !important;
border-bottom-left-radius: 14px !important;
background: rgba(176, 186, 195, 0.4) !important;
border: none !important;
}

.react-tel-input .form-control , .react-tel-input .flag-dropdown.open .selected-flag {
  background: rgba(176, 186, 195, 0.4) !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background: rgba(176, 186, 195, 0.4) !important;
}