.faq-page {
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
}
.faq-page .side-nav {
  width: 326px;
  min-width: 326px;
  min-height: 853px;
  background-repeat: no-repeat;
  background-size: contain;
}
.faq-page .faq-wrapper {
  width: 100%;
  flex: 1;
  padding: 0px 30px;
  color: #090909;
}
.faq-page .faq-wrapper .heading-01 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 3px;
}
.faq-page .faq-wrapper .faq-list .faq {
  border-radius: 18px;
  box-shadow: 0px 5px 16px 0px #080f340f;
  background: #ffffff;
  padding: 26px 26px;
  margin-bottom: 20px;
}
.faq-page .faq-wrapper .faq-list .faq.open-faq {
  border: 2px solid #23aeb1;
}
.faq-page .faq-wrapper .faq-list .faq.open-faq .faq-title .faq-toggle-btn {
  background: #23aeb1 !important;
}
.faq-page .faq-wrapper .faq-list .faq .faq-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #170f49;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faq-page .faq-wrapper .faq-list .faq .faq-title .faq-toggle-btn {
  background: #ffffff;
  box-shadow: 0px 5px 16px 0px #080f340f;
  width: 50.66px;
  height: 50.66px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
}
.faq-page .faq-wrapper .faq-list .faq .faq-desc {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  color: #6f6c90;
  line-height: 1.4;
  padding-top: 12px;
  width: 87%;
}

@media (max-width: 992px) {
  .faq-page .side-nav {
    display: none;
  }
  .faq-page .faq-wrapper .faq-list .faq .faq-title .faq-toggle-btn {
    width: 38.66px;
    height: 38.66px;
  }
  .faq-page .faq-wrapper .faq-list .faq {
    padding: 16px 16px;
  }
  .faq-page .faq-wrapper .faq-list .faq .faq-title .title {
    font-size: 16px;
    line-height: 1.6;
    width: 80%;
  }
  .faq-page .faq-wrapper .faq-list .faq .faq-desc {
    font-size: 13px;
  }
}
