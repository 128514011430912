.privacy-policy h1 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2.8px;
}

@media (max-width: 992px) {
    .privacy-policy h1 {
        font-size: 16px;
    }
}
.privacy-policy .hero-section{
    background-color: #000;
    padding-top: 40px;
    padding-bottom: 30px;
}