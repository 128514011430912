.about-us-page {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}
.about-us-page-wrapper {
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
}
.about-us-page .side-bar {
  background-color: #0000006b;
  width: 236px;
  min-width: 236px;
  height: 100vh;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 40px 20px 20px 40px;
}
.about-us-page .side-bar .links {
  margin-bottom: 20px;
  cursor: pointer;
}
.about-us-page .side-bar .links .link:hover {
  color: #25adb1;
}
.about-us-page .side-bar .links .link {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
  padding-bottom: 6px;
  text-transform: uppercase;
}
.about-us-page .side-bar .links .link.active {
  color: #25adb1;
  border-bottom: 1px solid #25adb1;
}
.about-us-page .about-us-page-wrapper .about-us-content {
  padding: 20px 20px 20px 60px;
  width: 100%;
}
.about-us-page .about-us-content .app-icon-store-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  /* position: absolute;
  right: 0;
  bottom: 100px; */
}
.about-us-page .about-us-content .app-icon-store-wrapper img {
  border: 1px solid #fff;
  border-radius: 8px;
  width: 116px;
}
.about-us-page .about-us-content .our-mission h2 {
  text-transform: uppercase;
  font-weight: 600;
}
.about-us-page .about-us-content .team-wrapper {
  margin-bottom: 30px;
  text-align: center;
}
.about-us-page .about-us-content .team-wrapper .avatar {
  margin-bottom: 6px;
}
.about-us-page .about-us-content .team-wrapper .avatar img {
  width: 145px;
  height: 145px;
}
.about-us-page .about-us-content .team-wrapper .team-info {
}
.about-us-page .about-us-content .team-wrapper .team-info .name {
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}
.about-us-page .about-us-content .team-wrapper .team-info .title {
  font-weight: 300;
  color: #fff;
}
.text-color {
  color: var(--text-color) !important;
}
.text-right {
  text-align: right !important;
}
.fs-16 {
  font-size: 16px;
}


@media (max-width: 992px) {
  .about-us-page .side-bar {
    display: none;
  }
  .about-us-page .about-us-page-wrapper .about-us-content {
    padding: 20px 0px;
  }
}
