.home-page {
  background-color: #fff;
}
.home-page .hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.home-page .hero h1 {
  font-weight: 700;
  color: #fff;
  font-size: 56px;
}
.home-page .app-icon-store-wrapper .app-icon-store-img {
  border-radius: 8px;
  border: 1px solid #fff;
  width: 180px;
  height: 60px;
  z-index: 99;
  position: relative;
}
.home-page .hero .hero-app-promo-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-page .hero .hero-app-promo-img-wrapper img {
  width: 80%;
}
.home-page .hero .hero-app-promo-img-wrapper .hero-section-links ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.home-page .hero .hero-app-promo-img-wrapper .hero-section-links ul li {
  padding: 0px 12px;
  padding-top: 14px;
}
.home-page .hero .hero-app-promo-img-wrapper .hero-section-links ul li a {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
.home-page .hero .hero-app-promo-img-wrapper .powered-by {
  font-size: 13px;
  text-align: center;
}
/* trailblazing-features css */
.home-page .trailblazing-features {
  padding-top: 70px;
  padding-bottom: 40px;
}
.home-page .trailblazing-features .section-heading {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.home-page .trailblazing-features img {
  height: 400px;
  margin-bottom: 20px;
}

/* testimonials css */
.home-page .testimonials {
  padding-top: 60px;
  padding-bottom: 60px;
  background-size: 100% 101%;
  background-repeat: no-repeat;
}
.home-page .testimonials .section-heading {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.home-page .testimonials .section-heading.small {
  font-size: 30px;
  text-transform: capitalize;
}
.home-page .testimonials .testimonial-wrap {
  max-width: 270px;
  margin: auto;
  margin-top: 100px;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-page .testimonials .testimonial-wrap .designation {
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  min-height: 24px;
}
.home-page .testimonials .testimonial-wrap img.avatar {
  border-radius: 30px;
  height: 114px;
  width: 114px;
  margin-top: -80px;
}
.home-page .testimonials .testimonial-wrap .name {
  color: #25adb1;
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.home-page .testimonials .testimonial-wrap .text {
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
}
.home-page .testimonials .testimonial-wrap .rating img {
  width: 100px;
  margin-top: 10px;
}

/* download-now-section */
.home-page .download-now-section {
  background: linear-gradient(to left, #01898d, #02545d);
  border-radius: 30px;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 40px;
  position: relative;
  min-height: 350px;
  display: flex;
  align-items: center;
}
.home-page .download-now-section h4 {
  color: #fff;
  font-weight: 700;
  font-size: 30px;
}
.home-page .download-now-section p {
  font-weight: 300;
  color: #fff;
  font-size: 20px;
}

/* get-exclusive-updates */
.home-page .get-exclusive-updates {
  padding-top: 90px;
}
.home-page .get-exclusive-updates .get-exclusive-updates-input-wrapper {
  margin-top: 16px;
  background-color: #fff;
  border-radius: 20px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
}
.home-page .get-exclusive-updates .get-exclusive-updates-input-wrapper input {
  border: none;
  background-color: transparent;
  color: #000;
  font-size: 13px;
  width: 100%;
}
.home-page
  .get-exclusive-updates
  .get-exclusive-updates-input-wrapper
  input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.home-page
  .get-exclusive-updates
  .get-exclusive-updates-input-wrapper
  .btn-submit-subscription {
  background-color: #00898d;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  width: 200px;
}
.home-page .grow-master .heading-img img.grow-master-section-title {
  width: 100%;
}
.toaster-box {
  background-color: #00898d;
  color: white !important;
  /* animation: fade-in 0.5s ease-in-out; */
}

.toaster-box .Toastify__close-button {
  color: white !important;
}
.toaster-box .Toastify__toast-body::before {
  color: white !important;
}

.toaster-box .custom-progress {
  background-color: white;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-out {
  animation: fade-in-out 2s forwards;
}

@keyframes fade-in-out {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}
@media (min-width: 880px) and (max-width: 1024px) {
  .home-page .download-now-section .download-now-mobile-app-mockup {
    position: absolute;
    width: 470px !important;
    top: 10px !important;
    right: 10px !important;
  }
}
/* media mobile responsive */
@media (min-width: 992px) {
  .home-page .hero .app-icon-store-wrapper {
    position: absolute;
    bottom: 30px;
  }
  .home-page .download-now-section .download-now-mobile-app-mockup {
    position: absolute;
    width: 670px;
    top: -60px;
    right: -50px;
  }
  .home-page .download-now-section .scan-here {
    width: 110px;
    position: absolute;
    right: 30px;
    bottom: 16px;
  }
  .home-page .grow-master .sign-01 {
    position: absolute;
    width: 90px;
    left: 50px;
    top: 130px;
  }
  .home-page .grow-master .sign-02 {
    position: absolute;
    width: 140px;
    right: 50px;
    top: 80px;
  }
}
@media (max-width: 992px) {
  .home-page .hero h1 {
    text-align: center;
    font-size: 30px;
  }
  .home-page .app-icon-store-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .home-page .download-now-section .app-icon-store-wrapper {
    margin-bottom: 20px;
  }
  .home-page .app-icon-store-wrapper .app-icon-store-img {
    width: 100%;
    height: 100%;
  }
  .home-page .hero .hero-app-promo-img-wrapper .hero-section-links ul li {
    padding: 0px 6px;
  }
  .home-page .hero .hero-app-promo-img-wrapper .hero-section-links ul li a {
    font-size: 11px;
  }
  .home-page .hero .hero-app-promo-img-wrapper .powered-by {
    font-size: 11px;
    text-align: center;
  }
  .home-page .download-now-section .download-now-mobile-app-mockup {
    position: absolute;
    width: 240px;
    left: 0px;
    bottom: -10px;
  }
  .home-page .download-now-section .scan-here {
    width: 100px;
    right: 0px;
    bottom: -10px;
  }
  .home-page .download-now-section {
    padding: 40px 28px;
    padding-bottom: 10px;
  }
  .home-page .testimonials .section-heading {
    font-size: 30px;
  }
  .home-page .testimonials .section-heading.small {
    font-size: 20px;
  }
  .home-page .get-exclusive-updates {
    padding-top: 20px;
  }
  .home-page .grow-master .sign-01 {
    position: absolute;
    width: 40px;
    left: 20px;
    top: 30px;
  }
  .home-page .grow-master .sign-02 {
    position: absolute;
    width: 50px;
    right: 20px;
    top: 20px;
  }
}
.text-right {
  text-align: right;
}
